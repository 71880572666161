/* silicon v1.2.9 */
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;600;700;800&display=swap);

form,
nav {
    justify-content: center;
    display: flex
}

.width-l,
.width-m,
.width-s,
.width-xs {
    margin: 4rem auto
}

*,
button,
input,
select,
textarea {
    box-sizing: border-box;
    color: var(--color-text)
}

caption,
td,
th {
    text-align: left
}

a,
del,
ins,
s,
u {
    text-decoration-thickness: var(--border-width)
}

*,
button[type=reset],
code,
kbd,
samp {
    color: var(--color-text)
}

img,
svg {
    max-width: 100%;
    max-height: 100%
}

blockquote footer,
button:disabled,
input:disabled,
label {
    color: var(--color-darker-grey)
}

.flex,
form,
nav {
    display: flex
}

:root {
    --color-primary: #2c62af;
    --color-secondary-dark: #263342;
    --color-secondary: #cfdef3;
    --color-tertiary: #edf2f6;
    --color-background: #fff;
    --color-text: #0c0c0c;
    --color-border: #0c0c0c;
    --color-grey: #f1f1f1;
    --color-dark-grey: #ababab;
    --color-darker-grey: #818181;
    --color-green: #8ae77a;
    --color-red: #dc4e4e;
    --color-yellow: #eac66a;
    --offset-text-color: #f1f1f1;
    --font-size: 1.07rem;
    --xs-width: 20vw;
    --small-width: 50vw;
    --medium-width: 50vw;
    --large-width: 80vw;
    --xl-width: 100vw;
    --gap: 15px;
    --border-width: 2px
}

* {
    font-family: Outfit, sans-serif, system-ui
}

html {
    background-color: var(--color-background)
}

body {
    min-width: var(--small-width);
    width: min-content;
    margin: auto
}

h1 {
    font-size: 3em
}

h2 {
    font-size: 2.2em
}

caption,
h3 {
    font-size: 1.7em
}

h4 {
    font-size: 1.5em
}

h5 {
    font-size: 1.3em
}

h6 {
    font-size: 1.2em
}

a,
label {
    font-size: var(--font-size)
}

a {
    font-weight: 600;
    color: var(--color-primary)
}

nav {
    align-items: center;
    gap: 3rem;
    margin: 3rem 0
}

.flex,
div.gap,
form {
    gap: var(--gap)
}

.fullscreen {
    width: 100vw;
    height: 100vh
}

.width-xs {
    width: var(--xs-width);
    max-width: var(--xs-width)
}

.width-s {
    width: var(--small-width);
    max-width: var(--small-width)
}

.width-m {
    width: var(--medium-width);
    max-width: var(--medium-width)
}

.width-l {
    width: var(--large-width);
    max-width: var(--large-width)
}

.width-xl {
    width: var(--xl-width);
    max-width: var(--xl-width)
}

.height-100,
.width-100,
form,
form * {
    width: 100%
}

form {
    align-items: center;
    flex-flow: column;
    margin: 2rem 0
}

label {
    padding-bottom: 4px
}

input[type=checkbox],
input[type=range],
label {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: fit-content;
    display: inline-block
}

button,
input,
select,
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    padding: .8em;
    border-radius: 4px;
    background: 0 0;
    border: var(--border-width) solid var(--color-text);
    outline: 0;
    font-family: Outfit, sans-serif;
    font-size: var(--font-size);
    caret-color: var(--color-primary)
}

code,
code strong,
kbd,
samp,
var {
    font-family: 'JetBrains Mono', monospace
}

input:focus,
input[type=checkbox]:checked:before,
input[type=radio]:checked:before,
select:focus,
textarea:focus {
    animation: .3s ease-in-out forwards focus_border
}

button,
input[type=submit] {
    background-color: var(--color-primary);
    font-weight: 600;
    border: none;
    color: var(--offset-text-color);
    cursor: pointer;
    padding: .4rem 1.2rem
}

button:disabled,
button[type=reset],
input:disabled,
table.alternate tr th,
table:not(.alternate) tr:hover :not(th) {
    background-color: var(--color-grey)
}

input[type=submit] {
    padding: .8rem 1.2rem
}

::-moz-selection,
::selection {
    background-color: var(--color-primary);
    color: var(--color-background);
    border-radius: 4px
}

button {
    width: initial;
    margin: 0 4px
}

button:disabled,
input:disabled {
    font-weight: 500;
    border: var(--border-width) dashed var(--color-darker-grey)
}

button:disabled {
    padding: calc(.4rem - var(--border-width)) calc(1.2rem - var(--border-width))
}

button:disabled:hover,
input:disabled:hover {
    cursor: not-allowed
}

button[type=reset] {
    font-weight: 600
}

button:active,
input[type=submit]:active {
    transform: translateY(1px)
}

button:disabled:active {
    transform: none
}

input[type=color] {
    padding: 14px 4px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none
}

@-moz-document url-prefix() {
    input[type=color] {
        padding: 6px
    }
}

input[type=color]::-webkit-color-swatch {
    height: 16px;
    box-sizing: border-box;
    padding: 0;
    transform: translateY(-8px);
    border-radius: 3px;
    border: none
}

input[type=color]::-moz-color-swatch {
    border-radius: 3px;
    height: 16px;
    border: none
}

input[type=range] {
    height: 32px;
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
    border: 0;
    display: block
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: var(--color-background);
    border-radius: 4px;
    border: var(--border-width) solid var(--color-text);
    -webkit-appearance: none;
    padding: 0
}

input[type=range]::-webkit-slider-thumb {
    border: var(--border-width) solid var(--color-text);
    height: 16px;
    width: 16px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-appearance: none;
    transform: translateY(-5px);
    background: var(--color-text);
    box-shadow: inset -3px -3px 0 var(--color-background), inset 3px 3px 0 var(--color-background), inset 3px -3px 0 var(--color-background), inset -3px 3px 0 var(--color-background)
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: var(--color-background);
    border-radius: 4px;
    border: var(--border-width) solid var(--color-text)
}

input[type=range]::-moz-range-thumb {
    border: var(--border-width) solid var(--color-text);
    height: 12px;
    width: 12px;
    border-radius: 4px;
    cursor: pointer;
    background: var(--color-text);
    box-shadow: inset -3px -3px 0 var(--color-background), inset 3px 3px 0 var(--color-background), inset 3px -3px 0 var(--color-background), inset -3px 3px 0 var(--color-background)
}

input[type=range]::-ms-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    background: 0 0;
    border-color: transparent;
    color: transparent
}

input[type=range]::-ms-fill-lower {
    background: #74a9d8;
    border: 0 solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000
}

input[type=range]::-ms-fill-upper {
    background: #74a9d8;
    border: 0 solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: none;
    border: 1px solid #00001e;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #fff;
    cursor: pointer
}

input[type=file] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: var(--border-width) solid var(--color-text);
    padding: 6px
}

details,
input[type=file]:hover {
    cursor: pointer
}

::file-selector-button {
    border: var(--border-width) solid var(--color-text);
    background: 0 0;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: Outfit, system-ui, sans-serif;
    margin-right: 10px;
    color: var(--color-text);
    font-size: var(--font-size)
}

::-webkit-file-upload-button {
    border: var(--border-width) solid var(--color-text);
    background: 0 0;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: Outfit, system-ui, sans-serif;
    margin-right: 10px;
    color: var(--color-text);
    font-size: var(--font-size)
}

::-ms-browse {
    border: var(--border-width) solid var(--color-text);
    background: 0 0;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: Outfit, system-ui, sans-serif;
    margin-right: 10px;
    color: var(--color-text);
    font-size: var(--font-size)
}

select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="800" height="800" fill="none" viewBox="0 0 24 24"><path stroke="%230c0c0c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 11-3 3-3-3"/></svg>');
    background-position: calc(100% - .4em) calc(50% + 1px);
    background-repeat: no-repeat;
    background-size: 24px 24px
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none
}

input[type=number] {
    -moz-appearance: textfield
}

input[type=checkbox],
input[type=radio] {
    margin: 0 1rem 0 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    position: relative;
    cursor: pointer;
    padding: 0 6px;
    transform: translate(5px, 5px);
    border: none;
    outline: 0
}

input[type=checkbox]:before,
input[type=checkbox]:checked:after,
input[type=radio]:before,
input[type=radio]:checked:after {
    content: '';
    display: block
}

input[type=checkbox]:before,
input[type=radio]:before {
    position: absolute;
    width: 12px;
    height: 12px;
    border: 2px solid var(--color-text);
    transform: translate(-8px, -8px);
    border-radius: 4px
}

input[type=checkbox]:checked:after,
input[type=radio]:checked:after {
    width: 6px;
    height: 6px;
    background-color: var(--color-text);
    border-width: 0 var(--border-width) var(--border-width) 0;
    position: relative;
    transform: translate(-3px, -3px);
    border-radius: 2px
}

input[type=radio],
input[type=radio]:before,
input[type=radio]:checked:after {
    border-radius: 20px
}

::placeholder {
    opacity: 100%;
    color: var(--color-darker-grey)
}

table {
    border-collapse: collapse;
    width: 100%;
    margin: calc(var(--gap) * 2) 0
}

caption {
    margin-bottom: 1rem;
    font-weight: 600
}

code strong,
samp,
var {
    font-weight: 800
}

td,
th {
    padding: .4em
}

th {
    border-bottom: var(--border-width) solid var(--color-text);
    font-size: 1.2em
}

table.alternate tr th {
    border: none;
    padding: 1rem .5rem
}

code,
del,
ins,
kbd,
mark,
samp,
tr {
    border-radius: 4px
}

table.alternate tr th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 1rem
}

table.alternate tr th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-right: 1rem
}

table.alternate tr td {
    border-bottom: var(--border-width) solid var(--color-text)
}

table.alternate tr:last-child td {
    border-bottom: none
}

table.alternate tr td:first-child {
    border-left: 1rem solid var(--color-background)
}

table.alternate tr td:last-child {
    border-right: 1rem solid var(--color-background)
}

code,
samp {
    padding: .2rem .4rem;
    background-color: var(--color-secondary);
    font-size: .8rem;
    tab-size: 4;
    unicode-bidi: embed;
    white-space: pre-wrap
}

aside,
samp {
    background-color: var(--color-tertiary)
}

var {
    font-size: .9rem
}

.notice,
kbd {
    font-weight: 600
}

ol,
ul {
    padding-left: 1.2rem
}

li {
    margin: 5px 0
}

pre>code {
    display: block;
    margin: 4px 0;
    overflow: auto;
    padding: .6em
}

del {
    background-color: var(--color-red)
}

ins {
    background-color: var(--color-green)
}

mark {
    background-color: var(--color-yellow)
}

kbd {
    padding: .1rem .6rem;
    font-size: .8em;
    background-color: var(--offset-text-color);
    box-shadow: inset -2px -2px 0 #fff, inset 2px 2px 0 #fff, inset 2px -2px 0 #fff, inset -2px 2px 0 #fff;
    border: 1px solid var(--color-darker-grey);
    border-bottom: 2px solid var(--color-darker-grey);
    display: inline-block;
    text-transform: lowercase
}

img {
    border-radius: 4px
}

blockquote {
    margin-left: 1em
}

p {
    line-height: 1.4em;
    font-size: var(--font-size)
}

blockquote footer {
    margin: 1em 0 0 1em;
    font-size: .9em
}

blockquote footer::before {
    content: 'â€” '
}

hr {
    border: 1px solid var(--color-text)
}

details p {
    border-left: var(--border-width) solid var(--color-dark-grey);
    padding-left: 1em
}

aside {
    padding: .6em 1em;
    border-radius: 4px
}

aside p {
    margin: 0
}

.flex {
    justify-content: center;
    align-items: center;
    flex-direction: row
}

.flex.column {
    flex-direction: column
}

.flex.wrap {
    flex-wrap: wrap
}

.card {
    border: var(--border-width) solid var(--color-dark-grey);
    padding: 1em;
    border-radius: 4px
}

.notice {
    background-color: var(--color-background);
    border: var(--border-width) solid var(--color-primary);
    padding: .5rem;
    display: block;
    border-radius: 4px;
    margin-bottom: 1em;
    color: var(--color-primary);
    text-align: center;
    text-transform: uppercase
}

.notice.warning {
    border: calc(var(--border-width) * 1.5) solid var(--color-red);
    color: var(--color-red);
    font-weight: 700;
    padding: calc(.5rem - 2px)
}

.left {
    text-align: left
}

.center {
    text-align: center
}

.right {
    text-align: right
}

div.grid {
    display: grid
}

div.grid.two {
    grid-template-columns: repeat(2, 1fr)
}

div.grid.three {
    grid-template-columns: repeat(3, 1fr)
}

div.grid.four {
    grid-template-columns: repeat(4, 1fr)
}

div.grid.five {
    grid-template-columns: repeat(5, 1fr)
}

div.grid .span.c-2 {
    grid-column: span 2
}

div.grid .span.c-3 {
    grid-column: span 3
}

div.grid .span.c-4 {
    grid-column: span 4
}

div.grid .span.c-5 {
    grid-column: span 5
}

div.grid .span.r-2 {
    grid-row: span 2
}

div.grid .span.r-3 {
    grid-row: span 3
}

div.grid .span.r-4 {
    grid-row: span 4
}

div.grid .span.r-5 {
    grid-row: span 5
}

textarea.no-resize {
    resize: none
}

textarea.all-resize {
    resize: both
}

textarea.ho-resize {
    resize: horizontal
}

textarea.ve-resize {
    resize: vertical
}

input.small,
select.small,
textarea.small {
    padding: .4rem 1.2rem
}

button.big {
    padding: calc(.8em + 2px) 1.6em
}

button:disabled.small {
    padding: calc(.4rem - 2px) 1.2rem
}

.desktop-only {
    display: inherit
}

.mobile-only {
    display: none
}

@keyframes focus_border {
    0% {
        border-color: var(--color-text)
    }

    100% {
        border-color: var(--color-primary)
    }
}

@media (prefers-color-scheme:dark) {
    :root {
        --color-primary: #5783c4;
        --color-secondary: #263342;
        --color-tertiary: #161f28;
        --color-background: #091118;
        --color-text: #cbcbcb;
        --color-secondary-dark: #263342;
        --color-border: #0c0c0c;
        --color-grey: #1e2431;
        --color-dark-grey: #262b33;
        --color-darker-grey: #5d6c7e;
        --color-green: #1d4624;
        --color-red: #602020;
        --color-yellow: #67550c
    }

    kbd {
        background-color: var(--color-dark-grey);
        box-shadow: inset -2px -2px 0 var(--color-grey), inset 2px 2px 0 var(--color-grey), inset 2px -2px 0 var(--color-grey), inset -2px 2px 0 var(--color-grey);
        border-color: var(--color-border);
        color: var(--color-text)
    }

    select {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="800" height="800" fill="none" viewBox="0 0 24 24"><path stroke="%23cbcbcb" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 11-3 3-3-3"/></svg>') !important
    }
}

@media screen and (max-width:1000px) {
    :root {
        --xs-width: 40vw;
        --small-width: 60vw;
        --medium-width: 70vw;
        --large-width: 80vw
    }
}

@media screen and (max-width:500px) {
    :root {
        --xs-width: 90vw;
        --medium-width: 90vw;
        --small-width: 90vw;
        --large-width: 90vw
    }

    .desktop-only {
        display: none
    }

    .mobile-only {
        display: inherit
    }

    div.gap {
        gap: var(--gap)
    }

    div.grid {
        display: grid;
        grid-auto-rows: 1fr
    }

    div.grid.four {
        grid-template-columns: repeat(2, 1fr)
    }

    div.grid.five {
        grid-template-columns: repeat(3, 1fr)
    }

    div.grid .span.c-3,
    div.grid .span.c-4,
    div.grid .span.c-5 {
        grid-column: span 3
    }

    div.grid .span.r-3 {
        grid-row: span 3
    }

    div.grid .span.r-4 {
        grid-row: span 4
    }

    div.grid .span.r-5 {
        grid-row: span 5
    }
}